<template>
  <div class="fd-selector">
    <button
      class="fd-selector__option"
      :class="{
        active: isActive(option),
        animated_: isActive(option)
      }"
      v-for="(option, index) in options"
      :key="index"
      @click="onClick(option)"
    >
      {{ optionLabel(option) }}
    </button>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    value: {
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    optionLabel: {
      type: Function,
      default: (option) => option
    },
    optionValue: {
      type: Function,
      default: (option) => option
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    isActive(option) {
      return this.optionValue(option) === this.value;
    },
    onClick(option) {
      // Only change the model if value is different
      if (this.optionValue(option) !== this.value) {
        this.$emit("input", this.optionValue(option));
      }
      // Always emit click
      this.$emit("click", this.optionValue(option));
    }
  }
};
</script>

<style lang="scss">
.fd-selector {
  display: flex;
  .fd-selector__option {
    transition: all 0.3s;
    top: 0;
    padding: 12px 24px;
    color: $color-main;
    border: solid 1px $color-main;
    border-radius: 4px;
    text-transform: uppercase;
    width: 100%;
    &:not(:last-child) {
      margin-right: 8px;
    }
    &.active {
      background: $color-main;
      color: white;
      transition: all 0.3s;
      box-shadow: 0px 2px 10px #00000025;
      font-weight: bold;
    }
    &:hover {
      transition: top 0.1s ease-in-out 0s;
      background: $color-main;
      color: white;
      top: -2px;
      position: relative;
      box-shadow: 0px 4px 15px #00000035;
    }
  }
}
</style>
